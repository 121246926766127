import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /*
      <Title text="Projects" />
      - [Super Short Story Telling: Harry Potter](https://www.lekoarts.de/en/projects/docma-award-super-short-story-telling-about-harry-potter)
      - [Instagram Project "Proprius"](https://www.lekoarts.de/en/projects/private-instagram-project-proprius)
      */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      